import React from "react"

export default function Home() {
    return (
        <div className="section-container" id="home">
            <section className="section-home">
                <div className="home-left">
                    <img src="./images/nichole_artboutiki_square.png" alt="A smiling woman with short blonde hair. She's sitting at a piano, and behind her is a wall covered in record albums." className="home-headshot" />
                    <p>Hello, I am</p>
                    <h1 id="h1-el" className="h1-animation">Nichole Boaz</h1>
                </div>
                <div className="home-right">
                    <div>
                        <p>Hi, I'm Nichole, and this is my personal website.</p>
                        <p>I'm a musician located in the San Francisco Bay Area.</p>
                        <p>On this website you can find information about me, what I've been up to musically and otherwise, and my upcoming performances.</p>
                    </div>
                    <span className="home-social">
                        <a href="https://www.linkedin.com/in/jazznichole/">
                            <i className="fa-brands fa-linkedin"></i>
                        </a>
                        <a href="https://github.com/nicholeboaz">
                            <i className="fa-brands fa-square-github"></i>
                        </a>
                        <a href="https://7thstreetbigband.bandcamp.com/">
                            <i className="fa-brands fa-bandcamp"></i>
                        </a>
                    </span>
                    <p>
                        <a href="mailto:nichole.j.boaz@gmail.com">nichole.j.boaz@gmail.com</a>
                    </p>
                </div>
            </section>
        </div>
    )
}