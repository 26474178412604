import React from "react"

export default function Card(props) {

    // props.img
    // props.title
    // props.text
    // props.type (article-card, article-text, article-text-wide??)

    if (props.type === "article-card") {
        return (
            <article className={props.type}>
                <a href={props.link} className="card-link">
                    {props.preview}
                    <h4>{props.title}</h4>
                </a>
                <p>{props.text}</p>
            </article>
        )
    } else if (props.type === "article-text") {
        return (
            <article className={props.type}>
                <p>{props.text}</p>
            </article>
        )
    } else if (props.type === "article-list") {
        return (
            <article className={props.type}>
                <h4>{props.title}</h4>
                {props.text}
            </article>
        )
    }

}