import React from "react"
import Navbar from "./components/Navbar"
import Home from "./components/Home"
import About from "./components/About"
import Section from "./components/Section"
import Footer from "./components/Footer"

export default function App() {

  const musicCards = [
    {
      key: 1,
      preview: (
        <img
          src="./images/7thstreetlogo.png"
          className="card-img"
          alt="A pair of red sunglasses with multicolored lenses. On the lenses, stylized text reads '7th Street Big Band.'"
        />
      ),
      link: "https://7thstreetbigband.bandcamp.com/",
      title: "7th Street Big Band",
      text: "We're not your grandparents' big band—we're bringing some of the hippest beats to the table.",
      type: "article-card",
    },
    {
      key: 2,
      title: "Upcoming Gigs",
      text: (
        <ul>
          <li>September 28, 1:30pm | <a href="https://www.magwest.org/">MAGWest</a> | Performing with <a href="https://vvgo.org/">VVGO</a></li>
          <li>October 4, 8pm | The Art Boutiki | <a href="https://app.gopassage.com/events/akshara-24?iframe=true&prev=/users/33500921/events">Akshara</a></li>
          <li>October 19, 2pm | <a href="https://sjnotforsale.rsvpify.com/?securityToken=jJtKogu1zE44SEXf1M0qSydxft49z14S">San José Is Not For Sale: A Community Project</a> | <a href="https://7thstreetbigband.com/">7th Street Big Band</a></li>
        </ul>
      ),
      type: "article-list",
    },
    {
      key: 3,
      preview: (
          <img
            src="./images/sickones.png"
            className="card-img"
            alt="Sick Ones alt text goes here."
          />
        ),
      link: "https://www.instagram.com/gabbyandthesickones/",
      title: "Gabby and the Sick Ones",
      text: "Bringing the best vibes around with old school hip-hop, soul, and R&B covers.",
      type: "article-card",
    },

    // ----- One Man's Trash Card -----
    // {
    //   key: 3,
    //   preview: (
    //       <img
    //         src="./images/onemanstrash.png"
    //         className="card-img"
    //         alt="A smiling woman with long blond hair, wearing a red swimsuit."
    //       />
    //     ),
    //   link: "https://www.youtube.com/watch?v=uqWiAJqiDfI",
    //   title: "One Man's Trash",
    //   text: `Released in 2020, this music video is one of my favorite projects with 7th Street.`,
    //   type: "article-card",
    // },

    // ----- Post Hang Card -----

    // {
    //   key: 2,
    //   preview: (
    //       <img
    //         src="./images/posthang.jpg"
    //         className="card-img"
    //         alt="The album cover for Post Hang is a cartoon drawing of an outdoor party. The people in the background are smoking and chatting, while those in the foreground are drinking from red solo cups."
    //       />
    //     ),
    //   link: "https://7thstreetbigband.bandcamp.com/album/post-hang",
    //   title: "Post Hang!",
    //   text: "The latest release from 7th Street Big Band features 6 original tracks.",
    //   type: "article-card",
    // },

  ];

  const otherCards = [
    {
      key: 1,
      preview: (
        <div className="cat-game-preview">
          <i class="fa-solid fa-dungeon"></i>
          <i class="fa-solid fa-cat"></i>
          <i class="fa-solid fa-question"></i>
        </div>
      ),
      link: "http://nicholeboaz.com/catadventure/",
      title: "The Brave Little Kitten",
      text: "One of my first projects, this is a simple cat-themed RPG made with vanilla JavaScript.",
      type: "article-card"
    },
    {
      key: 2,
      // preview: "",
      // link: "",
      // title: "",
      text: "Stay tuned for future projects!",
      type: "article-text"
    },
    {
      key: 3,
      preview: (
        <img
          src="./logo512.png"
          className="card-img this-website-img"
          alt="The initials NB on a blue background"
        />
      ),
      link: "#",
      title: "This Website",
      text: "This website was made with React.",
      type: "article-card"
    }
  ]

  return (
    <div>
      <Navbar />
      <main>
        <Home />
        <About />
        <Section
          key={1}
          title="Music"
          id="music"
          cards={musicCards}
        />
        <Section
          key={2}
          title="Other Projects"
          id="other"
          cards={otherCards}
        />
      </main>
      <Footer />
    </div>
  )
}