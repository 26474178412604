import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

ReactDOM.render(<App />, document.getElementById('root'))

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

document.getElementById("menu-bars-el").addEventListener("click", () => {
  document.getElementById("menu-items-el").classList.toggle("hidden")
})

document.getElementById("menu-items-el").addEventListener("click", (e) => {
  document.getElementById("menu-items-el").classList.add("hidden")
})

const navBtns = [
  {btn: document.getElementById("home-btn"), target: document.getElementById("h1-el")},
  {btn: document.getElementById("about-btn"), target: document.getElementsByTagName("h2")},
  {btn: document.getElementById("music-btn"), target: document.getElementsByTagName("h2")},
  {btn: document.getElementById("code-btn"), target: document.getElementsByTagName("h2")}
]

for (let obj of navBtns) {
  obj.btn.addEventListener("click", () => {
    console.log(obj.target)
    if (obj.btn.id === "home-btn") {
      obj.target.classList.remove("h1-animation")
      void obj.target.offsetWidth
      obj.target.classList.add("h1-animation")
      console.log("foo")
    } else {
      for (let h2 of obj.target) {
        h2.classList.remove("h2-animation")
        void h2.offsetWidth
        h2.classList.add("h2-animation")
      }
    }
  })
}

// console.log(
//   window.location.hash
// )