import React from "react"

export default function About(props) {
    return (
        <div className="section-container" id="about">
            <section className="section-content">
                <h2 className="h2-animation">About</h2>
                <p className="about-text">
                    I'm a pianist, composer/arranger, and teacher. 
                    I received both my B.M. and M.A. in Jazz Studies from San Jose State University. 
                </p>
                <p className="about-text">
                    I've lived and worked in the South Bay Area for over a decade. 
                    I've taught students ranging in age from pre-school to post-retirement. 
                    My past work includes teaching at West Valley College and San Jose State University. 
                    I've also worked as a music transcriptionist for Rocksmith+. 
                </p>
                <p className="about-text">
                    I'm a founding member of <a href="https://7thstreetbigband.bandcamp.com/">7th Street Big Band</a>, and I've performed with them at the San Jose Jazz Festival, MAGWest, and many venues across the South Bay.
                </p>
                <p className="about-text">
                    Occasionally I branch out from jazz into other genres. In the past I've performed with all-women Queen tribute band The Killer Queens. More recently I've performed with Gabby and the Sick Ones, playing a genuine potpourri of genres including but not limited to hip-hop, R&B, country, and ska.
                </p>
                <p className="about-text">
                    In addition to my work as a musician, I've dabbled in software development, the main example of which is this website, which I built with React. 
                </p>
            </section>
        </div>
    )
}