import React from "react"

export default function Navbar() {
    return (
        <nav>
            <div className="menu-bars">
                <i className="fa-solid fa-bars" id="menu-bars-el"></i>
            </div>
            <div className="menu-items hidden" id="menu-items-el">
                <a href="#home" id="home-btn">Home</a>
                <a href="#about" id="about-btn">About</a>
                <a href="#music" id="music-btn">Music</a>
                <a href="#other" id="other-btn">Other Projects</a>
            </div>
        </nav>
    )
}