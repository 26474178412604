// import { PROPERTY_TYPES } from "@babel/types" /* idk where this came from??? I did not write it */
import React from "react"
import Card from "./Card"

export default function Section(props) {
    
    const [card1, card2, card3] = props.cards

    return (
        <div className="section-container" id={props.id}>
            <section className="section-content">
                <h2 className="h2-animation">{props.title}</h2>
                <div className="cards-container">
                    <Card
                        key={card1.key}
                        preview={card1.preview}
                        link={card1.link}
                        title={card1.title}
                        text={card1.text}
                        type={card1.type}
                    />
                    <Card
                        key={card2.key}
                        preview={card2.preview}
                        link={card2.link}
                        title={card2.title}
                        text={card2.text}
                        type={card2.type}
                    />
                    <Card
                        key={card3.key}
                        preview={card3.preview}
                        link={card3.link}
                        title={card3.title}
                        text={card3.text}
                        type={card3.type}
                    />
                </div>
            </section>
        </div>
    )
}